<template>
  <div class="btn-group" role="group" aria-label="...">
    <button type="button" class="btn btn-warning" @click="changeYear(-1)">
      <font-awesome-icon icon="fa-solid fa-circle-arrow-left" />
    </button>

    <button
      :class="getClassYear(getYear.previous)"
      type="button"
      class="btn btn-warning"
      @click="selectYear(getYear.previous)"
    >
      {{ getYear.previous }}
    </button>

    <button
      :class="getClassYear(getYear.current)"
      type="button"
      class="btn btn-warning"
      @click="selectYear(getYear.current)"
    >
      {{ getYear.current }}
    </button>

    <button
      :class="getClassYear(getYear.next)"
      type="button"
      class="btn btn-warning"
      @click="selectYear(getYear.next)"
    >
      {{ getYear.next }}
    </button>

    <button type="button" class="btn btn-warning" @click="changeYear(1)">
      <font-awesome-icon icon="fa-solid fa-circle-arrow-right" />
    </button>
  </div>
</template>

<script setup>
import { ref, reactive, computed, onBeforeMount } from 'vue';
import utilsAnalysis from '../../components/utils/utilsAnalysis.js';

import { defineEmits } from 'vue';

const emit = defineEmits(['changeYearComponent']);

const currentOffset = ref(0);
let year = reactive({});

onBeforeMount(() => {
  year = reactive(utilsAnalysis.getYears());
  selectYear(year.current);
});

const getYear = computed(function () {
  return year;
});

function changeYear(offset) {
  var tmp = year.selected;
  currentOffset.value = currentOffset.value + offset;
  const tmpObj = utilsAnalysis.getYearsOffset(currentOffset.value);
  tmpObj.selected = tmp;
  Object.assign(year, tmpObj);
}

function selectYear(parYear) {
  year.selected = parYear;
  emit('changeYearComponent', parYear);
}

function getClassYear(parYear) {
  if (year.selected === parYear) {
    return 'jractive';
  } else {
    return '';
  }
}
</script>
