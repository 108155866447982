import {
  createUserWithEmailAndPassword,
  getAuth,
  sendEmailVerification,
  signInWithEmailAndPassword,
} from 'firebase/auth';

import appUtils from '../components/utils/appUtils';
import config from '../store/global';
import { defineStore } from 'pinia';
import { getActivePinia } from 'pinia';
import { getDatabase } from 'firebase/database';
import { initializeApp } from 'firebase/app';
import { useAccountStore } from '../stores/AccountStore';
import { useInvestSymbolStore } from '../stores/InvestSymbolStore';
import { useInvestStore } from '../stores/InvestStore';
import { useCategoryStore } from '../stores/CategoryStore';
import { usePeriodicStore } from '../stores/PeriodicStore';
import { useRulesStore } from '../stores/RulesStore';
import { useSettingStore } from '../stores/SettingStore';
import { useTransStore } from '../stores/TransStore';
import { useUserStore } from '../stores/UserStore';
import { useFiltersStore } from '../stores/FiltersStore';
import { getPerformance } from 'firebase/performance';

//HOW TO USE:
//import { useAuthStore } from '../../stores/AuthStore';
//const authStore = useAuthStore();

export const useAuthStore = defineStore('AuthStore', {
  state: () => {
    return {
      userId: null,
      token: null,
      didAutoLogout: false,
      userEmail: null,
      currentUserUid: null,
      userCredential: null,

      //settings: [],
    };
  },
  getters: {
    isAuthenticated() {
      return !!this.token;
    },

    getActionCodeSettings() {
      const actionCodeSettings = {
        // URL you want to redirect back to. The domain (www.example.com) for this
        // URL must be in the authorized domains list in the Firebase Console.
        url: 'https://www.example.com/finishSignUp?cartId=1234',
        // This must be true.
        handleCodeInApp: true,
        iOS: {
          bundleId: 'com.example.ios',
        },
        android: {
          packageName: 'com.example.android',
          installApp: true,
          minimumVersion: '12',
        },
        dynamicLinkDomain: 'example.page.link',
      };
      return actionCodeSettings;
    },
  },
  actions: {
    logout() {
      this.clearPiniaStore();
    },

    clearPiniaStore() {
      const activeStores = Object.keys(getActivePinia().state.value);
      //console.log('clearPiniaStore => activeStores');
      //console.log(activeStores);
      activeStores.forEach((store) => {
        getActivePinia()._s.get(store).$reset();
      });
    },

    registerNewUser(payload) {
      console.log('registerNewUser');

      const firebaseConfig = config.global().firebaseConfig;
      const app = initializeApp(firebaseConfig);
      // const db = getDatabase(app);
      const auth = getAuth(app);

      // const auth = getAuth();
      console.log(auth);
      createUserWithEmailAndPassword(auth, payload.email, payload.password)
        .then((res) => {
          sendEmailVerification(res.user)
            .then(() => {
              console.log('Email sent!');
            })
            .catch((err) => console.log(err));
          console.log(res.user);
        })
        .catch((err) => console.log(err));
    },

    authenticateFirebase(payload) {
      console.log('authenticateFirebase');

      let promise = new Promise((resolve, reject) => {
        try {
          const firebaseConfig = config.global().firebaseConfig;
          const app = initializeApp(firebaseConfig);
          // const perf = getPerformance(app);
          getPerformance(app);
          const db = getDatabase(app);
          const auth = getAuth(app);

          signInWithEmailAndPassword(auth, payload.email, payload.password)
            .then((userCredential) => {
              // console.log(userCredential);
              this.userCredential = userCredential;
              this.currentUserUid = auth.currentUser.uid;
              this.token = auth.currentUser.accessToken;
              this.userEmail = auth.currentUser.email;

              useUserStore().userId = auth.currentUser.uid;

              // reading filter for initial search
              // of transactions from localstorage
              let filterObj = localStorage.getItem('filter');
              if (filterObj) {
                filterObj = JSON.parse(filterObj);
              } else {
                filterObj = {
                  searchTermin: appUtils.getCurrentYearMonth(),
                };
              }
              useUserStore().transFilter = filterObj;

              let promise1;
              let promise2;
              let promise3;
              let promise4;
              let promise5;
              let promise6;
              let promise7;
              let promise8;
              let promise9;
              let promise10;
              let promise11;

              let promises = [];
              let payloadPar = {
                db,
              };

              const accountStore = useAccountStore();
              promise1 = accountStore.loadAccountsFirebase(payloadPar);
              promise2 = accountStore.loadAccountGroups(payloadPar);
              promise3 = useSettingStore().loadSettings(payloadPar);
              promise4 = useCategoryStore().loadKontosFirebase(payloadPar);
              promise5 = usePeriodicStore().loadPeriodic(payloadPar);
              promise6 = useRulesStore().loadRules(payloadPar);

              // promise7 = useTransStore().getLastTrans({
              //   db: db,
              //   searchObj: { searchTermin: appUtils.getCurrentYearMonth() },
              // });
              promise7 = useTransStore().getLastTrans({
                db: db,
                searchObj: filterObj,
              });

              promise8 = useFiltersStore().loadFilters(payloadPar);

              promise9 =
                useInvestSymbolStore().loadInvestSymbolsFirebase(payloadPar);

              promise10 = useInvestStore().loadInvestFirebase(payloadPar);
              promise11 = useInvestStore().loadDividendsFirebase(
                payloadPar,
                appUtils.getCurrentYear()
              );

              promises.push(promise1);
              promises.push(promise2);
              promises.push(promise3);
              promises.push(promise4);
              promises.push(promise5);
              promises.push(promise6);
              promises.push(promise7);
              promises.push(promise8);
              promises.push(promise9);
              promises.push(promise10);
              promises.push(promise11);

              Promise.all(promises)
                .then(() => {
                  // console.log(userCredential.user.uid);

                  // console.log(
                  //   '=========== accountStore.accountGroupsWithGuid =========== '
                  // );
                  // console.log(accountStore.accountGroupsWithGuid);

                  useSettingStore().loadCurrencySymbol();

                  //localStorage.setItem('token', this.token);

                  resolve();
                })
                .catch((error) => {
                  // reject(new Error(error.message));
                  reject(new Error(error));
                });
            })
            .catch((error) => {
              reject();

              console.log(error);
              console.log(error.message);

              throw new Error(
                error.message ||
                  'Failed to authenticate. Check your login data.'
              );
            });
        } catch (error) {
          console.error(error);
          reject(new Error(error.message));
        }
      });

      return promise;
    },
  },
});
